// src/hooks/useFirebase.ts
import { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
	addDoc,
	setDoc,
	getDoc,
	getDocs,
	doc,
	updateDoc,
	startAfter,
	arrayUnion,
	arrayRemove,
	deleteDoc,
	collection,
	query,
	where,
	orderBy,
	limit,
} from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import { storage, deleteObject, db } from "../firebaseConfig";

import { useAuth } from "../context/AuthContext";

interface IPostPartial {
	authorName: string;
	title: string;
	excerpt: string;
	tags: string[];
	mainImage: File | null | string;
	body: string;
	isPremiumOnlyPost: boolean;
	displayName?: string | null;
}

const useFirebase = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const { currentUser } = useAuth();

	const uploadImage = async (file: File): Promise<string> => {
		try {
			const storageRef = ref(
				storage,
				`Images/${currentUser?.uid}/${new Date().getTime()}_${file.name}`,
			);
			await uploadBytes(storageRef, file);
			const url = await getDownloadURL(storageRef);
			return url;
		} catch (err: any) {
			setError(err.message);
			throw err;
		}
	};

	const addContactFormData = async (formData: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
		subject: string;
		message: string;
	}) => {
		try {
			setLoading(true);
			const ContactFormSubmissions = collection(
				db,
				"ContactFormSubmissions",
			);
			await addDoc(ContactFormSubmissions, {
				...formData,
				date: new Date().toISOString(),
			});
			setLoading(false);
		} catch (err: any) {
			setError(err.message);
			setLoading(false);
			console.error(err)
			throw err;
		}
	};

	const createPost = async (post: IPostPartial) => {
		console.log("Post in FB>> ", post);
		try {
			setLoading(true);

			// Get the user's document reference
			const userRef = doc(db, "Users", currentUser?.uid);

			// Update the user's document in the Users collection
			if (post.displayName) {
				await updateProfile(currentUser, {
					displayName: post.displayName,
				});
			}

			// upload the main image
			const imageUrl = await uploadImage(post.mainImage as File);

			// add post to the Posts collection
			const postsCollection = collection(db, "Posts");
			const postRef = await addDoc(postsCollection, {
				authorName: post.authorName,
				title: post.title,
				excerpt: post.excerpt,
				tags: post.tags,
				authorsId: [currentUser?.uid, currentUser.displayName],
				date: new Date().toISOString(),
				mainImage: imageUrl,
				isPremiumOnlyPost: post.isPremiumOnlyPost,
				body: post.body,
			});
			const postId = postRef.id;

			// Check if the user's document exists
			const userDoc = await getDoc(userRef);
			if (!userDoc.exists()) {
				// If the user's document doesn't exist, create it with an empty postsId array
				await setDoc(userRef, { postsId: [postId] });
			} else {
				// If the user's document exists, update it with the new post ID
				await updateDoc(userRef, {
					postsId: arrayUnion(postId),
				});
			}

			await updateDoc(userRef, {
				postsId: arrayUnion(postRef.id),
			});

			let p = (await getDoc(postRef)).data()!;
			p.id = postRef.id;

			console.log("useFirebase >> new post data", p);

			return p;
		} catch (err: any) {
			setError(err.message);
			throw err;
		} finally {
			setLoading(false);
		}
	};

	const checkUserIsAdmin = async (userId: string): Promise<boolean> => {
		try {
			setLoading(true);

			// Reference to the specific document in the Users collection
			const userRef = doc(db, "Users", userId);

			// Fetch the user's document
			const userDoc = await getDoc(userRef);

			// Check if the document exists and if the isAdmin property is true
			if (userDoc.exists()) {
				const userData = userDoc.data();
				console.log("useFirebase > userDoc", userData);
				return userData?.isAdmin === true;
			} else {
				throw new Error("User not found");
			}
		} catch (error: any) {
			console.error("Error checking admin status:", error.message);
			throw new Error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const updatePost = async (postId: string, updatedPost: IPostPartial) => {
		try {
			setLoading(true);

			// Get the reference to the post document
			const postRef = doc(db, "Posts", postId);

			// Fetch the existing post data
			const postDoc = await getDoc(postRef);
			if (!postDoc.exists()) {
				throw new Error("Post not found");
			}

			let imageUrl = postDoc.data()?.mainImage;

			// Check if there's a new image to upload
			if (updatedPost.mainImage instanceof File) {
				// Upload the new image
				imageUrl = await uploadImage(updatedPost.mainImage);

				// Delete the old image if it exists
				if (postDoc.data()?.mainImage) {
					const oldImageRef = ref(storage, postDoc.data().mainImage);
					await deleteObject(oldImageRef);
				}
			}

			// Update the post document with new data
			await updateDoc(postRef, {
				authorName: updatedPost.authorName,
				title: updatedPost.title,
				excerpt: updatedPost.excerpt,
				tags: updatedPost.tags,
				mainImage: imageUrl,
				body: updatedPost.body,
				isPremiumOnlyPost: updatedPost.isPremiumOnlyPost,
				lastUpdated: new Date().toISOString(),
			});

			console.log(`Post ${postId} has been updated successfully.`);
			return;
		} catch (error: any) {
			console.error("Error updating post:", error.message);
			throw new Error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const deletePost = async (postId: string) => {
		try {
			// Get the user's document reference
			const userRef = doc(db, "Users", currentUser?.uid);

			// get associated image url
			const postDoc = await getDoc(doc(db, "Posts", postId));
			const imageUrl = postDoc.data()?.mainImage;

			// Delete the post from the Posts collection
			const postRef = doc(db, "Posts", postId);
			await deleteDoc(postRef);

			// Delete the associated image from Firebase Storage
			if (imageUrl) {
				const imageRef = ref(storage, imageUrl);
				await deleteObject(imageRef);
			}

			// Remove the post ID from the user's document
			await updateDoc(userRef, {
				postsId: arrayRemove(postId),
			});

			console.log(
				`Post ${postId} and its associated image have been deleted.`,
			);
		} catch (error: any) {
			console.error("Error deleting post:", error.message);
			throw new Error(error.message);
		}
	};

	const getPostById = async (postId: string) => {
		try {
			// Reference to the specific document in the Posts collection
			const postRef = doc(db, "Posts", postId);

			// Fetch the document
			const postDoc = await getDoc(postRef);

			// Check if the document exists
			if (postDoc.exists()) {
				// Return the post data
				const postData = postDoc.data();

				return postData;
			} else {
				throw new Error("Post not found");
			}
		} catch (error: any) {
			console.error("Error fetching post:", error.message);
			throw new Error(error.message);
		}
	};

	const getPostsByUserId = async (userId: string, _limit: number = 3) => {
		try {
			setLoading(true);

			const postsCollection = collection(db, "Posts");
			const userPostsQuery = query(
				postsCollection,
				where("authorsId", "array-contains", userId),
				orderBy("date", "desc"),
				limit(_limit),
			);

			const querySnapshot = await getDocs(userPostsQuery);

			const userPosts = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			return userPosts;
		} catch (error: any) {
			console.error("Error fetching posts by user:", error.message);
			throw new Error(error.message);
		} finally {
			setLoading(false);
		}
	};

	const getRecentPosts = async (_startDoc = null, _limit: number = 4) => {
		try {
			const postsCollection = collection(db, "Posts");

			console.log(">>> pReq Start =", _startDoc)

			let recentPostsQuery;

			if (_startDoc) {
				// If there is a starting document, start after it
				recentPostsQuery = query(
					postsCollection,
					orderBy("date", "desc"),
					startAfter(_startDoc),
					limit(_limit),
				);
			} else {
				// Fetch the first batch of posts
				recentPostsQuery = query(
					postsCollection,
					orderBy("date", "desc"),
					limit(_limit),
				);
			}

			const querySnapshot = await getDocs(recentPostsQuery);

			// Get the last document for pagination
			const lastVisible =
				querySnapshot.docs[querySnapshot.docs.length - 1];

			const recentPosts = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));

			return { posts: recentPosts, lastVisible };
		} catch (error: any) {
			console.error("Error fetching recent posts:", error.message);
			throw new Error(error.message);
		}
	};

	return {
		uploadImage,
		createPost,
		deletePost,
		updatePost,
		loading,
		error,
		getPostById,
		getRecentPosts,
		getPostsByUserId,
		checkUserIsAdmin,
		addContactFormData,
	};
};

export const new_getRecentPosts = async (
	startAfterDoc: any | null,
	_limit: number,
) => {
	try {
		const postsCollection = collection(db, "Posts");

		let recentPostsQuery;
		if (startAfterDoc) {
			recentPostsQuery = query(
				postsCollection,
				orderBy("date", "desc"),
				startAfter(startAfterDoc),
				limit(_limit),
			);
		} else {
			recentPostsQuery = query(
				postsCollection,
				orderBy("date", "desc"),
				limit(_limit),
			);
		}

		const querySnapshot = await getDocs(recentPostsQuery);

		const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]; // Last document for pagination
		const recentPosts = querySnapshot.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));

		return { posts: recentPosts, lastVisible }; // Return posts and the last document
	} catch (error: any) {
		console.error("Error fetching recent posts:", error.message);
		throw new Error(error.message);
	}
};

export default useFirebase;
