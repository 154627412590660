// src/pages/About.tsx
import React from "react";

const Home: React.FC = () => {
	return (
		<div>
			<h1 className="text-2xl font-bold">PMS Home </h1>
			<p className="mt-4">Welcome to the PMS Home page.</p>
		</div>
	);
};

export default Home;
