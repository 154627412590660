const authErrorMessages: { [key: string]: string } = {
	"auth/invalid-credential": "The email or password is incorrect.",
	"auth/email-already-in-use":
		"The email address is already in use by another account.",
	"auth/invalid-email": "The email address is badly formatted.",
	"auth/operation-not-allowed":
		"Email/password accounts are not enabled. Please contact support.",
	"auth/weak-password":
		"The password is too weak. Please choose a stronger password.",
	"auth/user-disabled":
		"The user account has been disabled by an administrator.",
	"auth/user-not-found": "There is no user corresponding to this email.",
	"auth/wrong-password":
		"The password is invalid or the user does not have a password.",
	"auth/network-request-failed": "Network error. Please try again later.",
	"auth/too-many-requests":
		"Too many requests. Please wait a while and try again.",
	"auth/requires-recent-login":
		"Please log in again to perform this operation.",
	"auth/account-exists-with-different-credential":
		"An account already exists with the same email address but different sign-in credentials. Please use a different sign-in method.",
	// Add more error codes and messages as needed
};

const getAuthErrorMessage = (errorCode: string): string => {
	return (
		authErrorMessages[errorCode] ||
		"An unknown error occurred. Please try again."
	);
};

export default getAuthErrorMessage;
