import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
	return (
		<div className="container mx-auto px-4 py-10">
			<h1 className="mb-5 text-3xl font-bold">Privacy Policy</h1>
			<p className="mb-5">Effective date: Aug 08, 2024</p>
			<p className="mb-5">
				This Privacy Policy (Policy) applies to all websites and mobile
				applications owned and operated by Cool Press, including
				podcasts and other media services (collectively referred to as
				the "Site"). Please read this Policy carefully before using this
				Site. By using this Site, you agree to the terms of this Policy
				and any additional Privacy Notices. If you do not agree with the
				practices described in this Policy, please do not use this Site.
			</p>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">
				Additional Privacy Notices
			</h2>
			<p className="mb-5">
				This Policy may be supplemented by additional privacy notices
				(“Privacy Notices”) provided at the time we collect your
				information. If a Privacy Notice conflicts with this Policy, the
				Privacy Notice takes priority.
			</p>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">
				Personal Information We May Collect
			</h2>
			<p className="mb-5">
				We may collect the following types of personal information:
			</p>
			<ul className="mb-5 list-inside list-disc">
				<li>Contact information (Name, email, phone number, etc.)</li>
				<li>Communication, event, and marketing information</li>
				<li>Transactional information</li>
				<li>Inquiry information</li>
				<li>Site usage information</li>
				<li>Survey and demographic information</li>
				<li>Job applicant information</li>
			</ul>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">
				How We Use Your Information
			</h2>
			<p className="mb-5">We may use your information to:</p>
			<ul className="mb-5 list-inside list-disc">
				<li>
					Provide the information, products, and services you request
				</li>
				<li>Respond to your questions and provide customer service</li>
				<li>Personalize your experience on the Site</li>
				<li>Send you notifications and special offers</li>
				<li>Conduct surveys and audience research</li>
				<li>Improve our Site, products, and services</li>
				<li>Prevent fraud and other unlawful activities</li>
			</ul>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">
				How We Share Your Information
			</h2>
			<p className="mb-5">We may share your information with:</p>
			<ul className="mb-5 list-inside list-disc">
				<li>Third-party vendors and service providers</li>
				<li>Other users through User Submissions</li>
				<li>Business partners for marketing purposes</li>
				<li>As part of a business transfer</li>
				<li>To comply with laws</li>
			</ul>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">
				Security of Your Information
			</h2>
			<p className="mb-5">
				Cool Press takes reasonable precautions to protect your
				information. However, no data transmission over the Internet is
				completely secure.
			</p>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">
				Children’s Privacy
			</h2>
			<p className="mb-5">
				This Site is not directed at children under 13 years of age. We
				do not knowingly collect personal information from children
				under 13.
			</p>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">
				Links to Other Websites
			</h2>
			<p className="mb-5">
				This Site may contain links to third-party websites. This Policy
				does not apply to information collected through these websites.
			</p>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">Your Choices</h2>
			<p className="mb-5">
				You have choices regarding the collection, use, and sharing of
				your information:
			</p>
			<ul className="mb-5 list-inside list-disc">
				<li>
					Do Not Track Mechanisms: We do not honor "Do Not Track"
					signals.
				</li>
				<li>
					Marketing Emails: Opt-out by clicking the “Unsubscribe” link
					in promotional emails.
				</li>
				<li>
					Cookies: Manage cookie settings through your browser or
					device.
				</li>
				<li>
					Interest-Based Advertising: Adjust settings on your device.
				</li>
			</ul>

			<h2 className="mb-4 mt-8 text-2xl font-semibold">Contact Us</h2>
			<p className="mb-5">
				If you have questions or concerns about this Policy, please
				contact us at{" "}
				<Link className="text-blue-500" to="/contact">
					Contact Page
				</Link>
				.
			</p>

			<p className="mt-5">Last Updated: Aug 08, 2024</p>
		</div>
	);
};

export default PrivacyPolicy;
