// src/components/CookieConsent.tsx
import React, { useState, useEffect } from "react";

const CookieConsent: React.FC = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowConsent(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 bg-gray-900 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <p className="text-sm">
          We use cookies to enhance your experience. By continuing to visit
          this site you agree to our use of cookies.
        </p>
        <div>
          <button
            className="text-green-500 hover:text-green-700 border border-transparent hover:border-green-700 px-4 py-2 rounded mr-2"
            onClick={handleAccept}
          >
            Accept
          </button>
          <button
            className="text-red-500 hover:text-red-700 border border-transparent hover:border-red-700 px-4 py-2 rounded"
            onClick={handleDecline}
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
