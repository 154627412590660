import RecentThreePostList from "../components/RecentThreePostList";

import { Link } from "react-router-dom";

const Home: React.FC = () => {
	return (
		<div className="pt-8">
			<h1 className="mb-2 text-2xl font-bold">Recent Posts</h1>
			<RecentThreePostList />
			<div className="mt-4 flex justify-center">
				<Link
					to="/posts"
					className="rounded-md border border-blue-500 px-4 py-2 font-bold text-blue-500 transition-colors hover:bg-blue-500 hover:text-white"
				>
					See more posts
				</Link>
			</div>

		</div>
	);
};

export default Home;
