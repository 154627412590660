import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { NextUIProvider } from "@nextui-org/react";

import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";

import { AuthProvider } from "./context/AuthContext";
import { PostsProvider } from "./context/PostsContext";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<AuthProvider>
			<PostsProvider>
				<NextUIProvider>
					<RouterProvider router={router} />
				</NextUIProvider>
			</PostsProvider>
		</AuthProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
