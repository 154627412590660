import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import useFirebase from "../hooks/useFirebase";
import useTheme from "../hooks/useTheme";

const Contact = () => {
	const { isDarkTheme } = useTheme();
	const { addContactFormData } = useFirebase();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	const [formStatus, setFormStatus] = useState("");
	const [captchaPass, setCaptchaPass] = useState(false);

	const onCaptchaPass = () => {
		setCaptchaPass(true);
	};

	const resetForm = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setPhone("");
		setSubject("");
		setMessage("");
		setCaptchaPass(false);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!captchaPass) {
			alert("Please complete reCaptcha to verify that you are human!");
			return;
		}

		try {
			await addContactFormData({
				firstName,
				lastName,
				email,
				phone,
				subject,
				message,
			});

			setFormStatus(
				"Thank you for your message. We will get back to you shortly.",
			);
			resetForm();
		} catch (error) {
			setFormStatus("Something went wrong. Please try again.");
		}
	};

	return (
		<div>
			<div className="container mx-auto px-4 py-8">
				<h1 className="mb-4 text-4xl font-bold">Contact Us</h1>
				<p className="mb-8 text-lg">
					We would love to hear from you! Please use the form below to
					get in touch with us, and we will respond as soon as
					possible.
				</p>
				
				<form onSubmit={handleSubmit} className="space-y-4">
					<div className="flex space-x-4">
						<div className="w-full">
							<label
								htmlFor="first-name"
								className="mb-2 block text-lg font-medium"
							>
								First Name
							</label>
							<input
								type="text"
								id="first-name"
								name="first-name"
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								required
								className="w-full rounded border px-4 py-2"
							/>
						</div>
						<div className="w-full">
							<label
								htmlFor="last-name"
								className="mb-2 block text-lg font-medium"
							>
								Last Name
							</label>
							<input
								type="text"
								id="last-name"
								name="last-name"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								required
								className="w-full rounded border px-4 py-2"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="email"
							className="mb-2 block text-lg font-medium"
						>
							Email
						</label>
						<input
							type="email"
							id="email"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className="w-full rounded border px-4 py-2"
						/>
					</div>
					<div>
						<label
							htmlFor="phone"
							className="mb-2 block text-lg font-medium"
						>
							Phone
						</label>
						<input
							type="text"
							id="phone"
							name="phone"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							required
							className="w-full rounded border px-4 py-2"
						/>
					</div>
					<div>
						<label
							htmlFor="subject"
							className="mb-2 block text-lg font-medium"
						>
							Subject
						</label>
						<input
							type="text"
							id="subject"
							name="subject"
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
							className="w-full rounded border px-4 py-2"
						/>
					</div>
					<div>
						<label
							htmlFor="message"
							className="mb-2 block text-lg font-medium"
						>
							Message
						</label>
						<textarea
							id="message"
							name="message"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							required
							rows={4}
							className="w-full rounded border px-4 py-2"
						></textarea>
					</div>

					<ReCAPTCHA
						sitekey={
							process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY ||
							""
						}
						onChange={onCaptchaPass}
						theme={isDarkTheme ? "dark" : "light"}
					/>

					<button
						type="submit"
						className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
					>
						Send Message
					</button>
				</form>
				{formStatus && (
					<div
						className={`my-4 p-4 ${
							formStatus.startsWith("Thank")
								? "bg-green-100 text-green-700"
								: "bg-red-100 text-red-700"
						} border border-${
							formStatus.startsWith("Thank") ? "green" : "red"
						}-300 rounded`}
					>
						{formStatus}
					</div>
				)}
			</div>
		</div>
	);
};

export default Contact;
