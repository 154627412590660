import { useState, useEffect } from "react";

const useTheme = () => {
	const [theme, setTheme] = useState<string>(() => {
		const savedTheme = localStorage.getItem("theme");
		return savedTheme || "system";
	});

	useEffect(() => {
		const root = document.documentElement;

		const applyTheme = () => {
			if (theme === "system") {
				const prefersDark = window.matchMedia(
					"(prefers-color-scheme: dark)",
				).matches;
				root.classList.toggle("dark", prefersDark);
			} else {
				root.classList.toggle("dark", theme === "dark");
			}
		};

		applyTheme();
		localStorage.setItem("theme", theme);

		const handleSystemThemeChange = (e: MediaQueryListEvent) => {
			if (theme === "system") {
				root.classList.toggle("dark", e.matches);
			}
		};

		const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
		mediaQuery.addEventListener("change", handleSystemThemeChange);

		return () => {
			mediaQuery.removeEventListener("change", handleSystemThemeChange);
		};
	}, [theme]);

	const toggleTheme = () => {
		setTheme((prev) => {
			if (prev === "light") return "dark";
			if (prev === "dark") return "system";
			return "light";
		});
	};

	return {
		theme,
		isDarkTheme: theme === "dark",
		toggleTheme,
	};
};

export default useTheme;
