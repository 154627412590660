// src/pages/About.tsx
import React from "react";

const About: React.FC = () => {
	return (
		<div>
			<h1 className="text-2xl font-bold">PMS About </h1>
			<p className="mt-4">Welcome to the PMS About page.</p>
		</div>
	);
};

export default About;
