import React, { useEffect, useState, useRef, useCallback } from "react";
import BlogPostItem from "../components/BlogPostItem";
import SearchInput from "../components/SearchInput";
import { usePosts } from "../context/PostsContext";

const RecentPostsPage: React.FC = () => {
  const { posts, loading, error, loadMorePosts } = usePosts(); // Get posts, loading, and error from context
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadMoreCalled = useRef(false); // Ref to track if loadMorePosts has already been called

  // Memoized load more function to avoid re-creation on every render
  const handleLoadMorePosts = useCallback(async () => {
    if (!loadMoreCalled.current) {
      loadMoreCalled.current = true; // Set the flag to prevent multiple triggers
      setIsLoadingMore(true); // Set loading indicator
      await loadMorePosts(); // Trigger load more
      setIsLoadingMore(false); // Reset loading indicator
      loadMoreCalled.current = false; // Reset flag after loading completes
    }
  }, [loadMorePosts]);

  // Infinite scroll handler
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 350 &&
      !isLoadingMore // Only load more if it's not already loading
    ) {
      handleLoadMorePosts(); // Trigger the memoized load more function
    }
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleLoadMorePosts]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="pt-8">
      <div className="mb-4">
        <SearchInput placeholder="Search posts..." classNames={{ input: "block sm:hidden" }} />
      </div>

      {posts.map((post: any) => (
        <BlogPostItem key={post.id} post={post} />
      ))}

      {isLoadingMore && (
        <div className="flex justify-center items-center mt-4">
          <p>Loading more posts...</p>
        </div>
      )}
    </div>
  );
};

export default RecentPostsPage;
