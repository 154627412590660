// src/pages/About.tsx
import React from "react";
import { Link } from "react-router-dom";

const TermsAndConditions: React.FC = () => {
	return (
		<div className="mx-auto max-w-4xl p-6">
			<h1 className="mb-4 text-3xl font-bold">Terms and Conditions</h1>
			<p className="mb-4">Effective date: Aug 08, 2024</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				1. Acceptance of Terms
			</h2>
			<p className="mb-4">
				By accessing or using the services provided by Cool Press, you
				agree to comply with and be bound by these Terms and Conditions.
				If you do not agree with any part of these terms, please do not
				use our services.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				2. Changes to Terms
			</h2>
			<p className="mb-4">
				Cool Press reserves the right to modify these Terms at any time.
				We will notify you of changes by posting the new Terms on our
				website. You are advised to review these Terms periodically for
				any changes.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				3. User Obligations
			</h2>
			<p className="mb-4">
				You agree to use our services in compliance with all applicable
				laws and regulations. You will not engage in any activities that
				may harm or interfere with our services.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				4. Intellectual Property
			</h2>
			<p className="mb-4">
				All content provided by Cool Press, including text, graphics,
				logos, and software, is the property of Cool Press and is
				protected by copyright and other intellectual property laws. You
				may not reproduce, distribute, or modify any content without our
				prior written consent.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				5. Limitation of Liability
			</h2>
			<p className="mb-4">
				In no event shall Cool Press be liable for any indirect,
				incidental, special, or consequential damages arising out of or
				in connection with your use of our services. Our liability is
				limited to the fullest extent permitted by law.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				6. Governing Law
			</h2>
			<p className="mb-4">
				These Terms shall be governed by and construed in accordance
				with the laws of the State in which Cool Press operates, without
				regard to its conflict of law principles.
			</p>
			<h2 className="mb-2 mt-6 text-2xl font-semibold">
				7. Contact Information
			</h2>
			<p className="mb-4">
				If you have any questions or concerns about these Terms, please{" "}
				<Link className="text-blue-500" to="/contact">
					Contact Us.
				</Link>
			</p>
			<p className="mt-4">Last Updated: Aug 08, 2024</p>
		</div>
	);
};

export default TermsAndConditions;
