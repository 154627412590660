// NotFound.tsx
import { useRouteError, Link } from "react-router-dom";

export default function NotFound() {
	const error: any = useRouteError();
	console.error(error);

	return (
		<div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 dark:bg-gray-900">
			<div className="text-center">
				<h1 className="mb-4 text-6xl font-bold text-blue-500 dark:text-blue-400">
					404
				</h1>
				<h2 className="mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
					Oops! Page not found.
				</h2>
				<p className="mb-8 text-gray-600 dark:text-gray-400">
					Sorry, the page you are looking for does not exist or has
					been moved.
				</p>
				{error && (
					<p className="mb-4 text-gray-500 dark:text-gray-400">
						<i>{error.statusText || error.message}</i>
					</p>
				)}
				<Link
					to="/"
					className="rounded-full bg-blue-500 px-6 py-2 text-white shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
				>
					Go back to the home page
				</Link>
			</div>
		</div>
	);
}
