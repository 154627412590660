import { createBrowserRouter } from "react-router-dom";

// import Root from "./root";

import Layout from "../Layout";

import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import About from "../pages/About";
import Contact from "../pages/Contact";
import CopyRight from "../pages/CopyRight";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import BlogPost from "../pages/BlogPost";
import Write from "../pages/Write";
import Posts from "../pages/Posts";

// PMS
import PMSLayout from "../PMS/Layout";
import PMSHome from "../PMS/pages/Home";
import PMSAbout from "../PMS/pages/About";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		errorElement: <NotFound />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "/signin",
				element: <SignIn />,
			},
			{
				path: "/signup",
				element: <SignUp />,
			},
			{
				path: "/post/:id",
				element: <BlogPost />,
			},
			{
				path: "/write",
				element: <Write />,
			},
			{
				path: "/write/:id",
				element: <Write />,
			},
			{
				path: "/posts",
				element: <Posts />,
			},
			{
				path: "/posts/:uid",
				element: <Posts />,
			},
			{
				path: "/about",
				element: <About />,
			},
			{
				path: "/contact",
				element: <Contact />,
			},
			{
				path: "/copyright",
				element: <CopyRight />,
			},
			{
				path: "/privacy-policy",
				element: <PrivacyPolicy />,
			},
			{
				path: "/terms-and-conditions",
				element: <TermsAndConditions />,
			},
		],
	},
	{
		path: "/pms",
		element: <PMSLayout />,
		errorElement: <NotFound />,
		children: [
			{
				path: "/pms",
				element: <PMSHome />,
			},
			{
				path: "/pms/about",
				element: <PMSAbout />,
			},
		],
	},
]);
