// src/components/Loading.tsx
import React from "react";
import Logo from "./Logo";

const Loading: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        {/* <img src="/assets/images/LogoAnimation.gif" alt="Loading..." className="w-16 h-16 mx-auto mb-4 " /> */}
        <div className="w-16 h-16 mx-auto mb-4 "><Logo  /></div>
        
        <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">Fetching the post...</p>
      </div>
    </div>
  );
};

export default Loading;
