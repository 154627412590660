// SignUpPrompt.tsx
import React from "react";
import { useNavigate, Location } from "react-router-dom";

const SignUpPrompt: React.FC<
	React.PropsWithChildren<{ location: Location }>
> = ({ location }) => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate("/signup", { state: { from: location } });
	};
	return (
		<div className="animate-slide-up fixed bottom-0 left-0 z-20 w-full bg-white p-6 text-center shadow-lg dark:bg-gray-800">
			<div className="container mx-auto">
				<h2 className="mb-4 text-2xl font-bold">
					Sign Up to Continue Reading
				</h2>
				<p className="mb-4">
					To access the full content, please sign up or log in.
				</p>
				<button
					onClick={handleClick}
					className="rounded-full bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
				>
					Sign In / Sign Up
				</button>
			</div>
		</div>
	);
};

export default SignUpPrompt;
