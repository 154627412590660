import React, { createContext, useContext, useState, useEffect } from "react";
import useFirebase from "../hooks/useFirebase"; // Import useFirebase

const PostsContext = createContext();

export const usePosts = () => useContext(PostsContext);

export const PostsProvider = ({ children }) => {
	const { getRecentPosts, updatePost, deletePost, createPost, loading, error } = useFirebase();
	const [posts, setPosts] = useState([]);
	const [lastDoc, setLastDoc] = useState(null); // To keep track of the last document for pagination
	const [postsLoading, setPostsLoading] = useState(true);
	const [postsError, setPostsError] = useState(null);

	// Fetch all posts once when the component mounts
	useEffect(() => {
		const fetchInitialPosts  = async () => {
			try {
				const { posts, lastVisible } = await getRecentPosts();
				setPosts(posts);
				setLastDoc(lastVisible);
				setPostsLoading(false);

			} catch (err) {
				setPostsError("Failed to fetch posts.");
				setPostsLoading(false);

			} finally {
				setPostsLoading(false);
			}
		};

		fetchInitialPosts ();
	}, [])

	const loadMorePosts = async () => {
		if (lastDoc) {
		  try {
			const { posts: newPosts, lastVisible } = await getRecentPosts(lastDoc);

			// console new posts titles and length
			console.log("New Post len = ", newPosts.length)

			setPosts((prevPosts) => {
				const newUniquePosts = newPosts.filter(
				  (newPost) => !prevPosts.some((prevPost) => prevPost.id === newPost.id)
				);
				return [...prevPosts, ...newUniquePosts];
			  });
			  

			setLastDoc(lastVisible); // Update the last document
		  } catch (error) {
			setPostsError(error.message);
		  }
		}
	  };

	// Edit post function
	const editPost = async (postId, updatedPost) => {
		try {
			await updatePost(postId, updatedPost);
			setPosts((prevPosts) =>
				prevPosts.map((post) => (post.id === postId ? { ...post, ...updatedPost } : post))
			);
		} catch (err) {
			setPostsError("Failed to edit post.");
		}
	};

    const addPost = async (newPost) => {
        try {

            console.log(">> Creating post in PostContext >> addPost()")
          // Add post to Firebase
          const createdPost = await createPost(newPost);

          console.log(">> PostContext >> created post", createdPost)

    
          // Update state with the new post
          setPosts((prevPosts) => [createdPost, ...prevPosts]);
          return createdPost.id
        } catch (error) {
          console.error("Error adding post: ", error);
        }
      };

	// Delete post function
	const removePost = async (postId) => {
		try {
			await deletePost(postId);
			setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
		} catch (err) {
			setPostsError("Failed to delete post.");
		}
	};

	const value = {
		posts,
		loading: postsLoading || loading,
		error: postsError || error,
		editPost,
		removePost,
        addPost,
		loadMorePosts
	};

	return (
		<PostsContext.Provider value={value}>
			{!postsLoading && children}
		</PostsContext.Provider>
	);
};
