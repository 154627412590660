import { IoIosShareAlt } from "react-icons/io";

const ShareButton = ({title, text, url}: {title: string, text: string, url: string}) => {

    const handleShare = async () => {


    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text,
          url,
        });
        console.log("Blog post shared successfully!");
      } catch (error) {
        console.error("Error sharing blog post:", error);
      }
    } else {
      // Fallback for browsers that don't support Web Share API
      alert("Web Share API is not supported in your browser.");
    }
  };
    
    return (
        <button
        onClick={handleShare}
        className=" text-blue-500 hover:text-blue-700 bg-transparent px-3 py-1"
      >
        <div className="flex flex-row items-center">
          <IoIosShareAlt size={26} className="mr-1" />
          Other
        </div>
      </button>
    )
}

export default ShareButton