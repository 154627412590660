import { Link, Outlet } from "react-router-dom";

export default function Layout() {
	return (
		<div className="flex min-h-screen flex-col px-8 sm:px-16 md:px-40">
			<header className="fixed left-0 top-0 z-50 flex w-full flex-row items-center justify-between">
				<p>PMS Header</p>
				<Link to="/">Cool Press</Link>
				<Link to="/pms">PMS</Link>
				<Link to="/pms/about">About</Link>
			</header>
			<main className="flex-grow pt-16">
				<div className="container mx-auto">
					<Outlet />
				</div>
			</main>
			<p>PMS Footer</p>
		</div>
	);
}
