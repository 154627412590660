import React, { useState, useEffect, useRef } from "react";
import { Input } from "@nextui-org/react";
import { IoSearch } from "react-icons/io5";
import { usePosts } from "../context/PostsContext";
import IPost from "../types/IPost";
import { Link } from "react-router-dom";
import clsx from "clsx"; // Import clsx for class merging

interface SearchInputProps {
  placeholder?: string; // Allows custom placeholder text
  onResultClick?: (id: string) => void; // Optional callback when a search result is clicked
  classNames?: {
    container?: string; // Custom class for container div
    input?: string; // Custom class for input
    dropdown?: string; // Custom class for dropdown
    resultItem?: string; // Custom class for result items
    noResults?: string; // Custom class for no results found
  };
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = "Search...",
  onResultClick,
  classNames = {},
}) => {
  const [searchQuery, setSearchQuery] = useState<string>(""); // Track the search input
  const [filteredPosts, setFilteredPosts] = useState<IPost[]>([]); // Store filtered posts
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false); // Control dropdown visibility
  const { posts } = usePosts(); // Get posts from PostsContext
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref to close the dropdown when clicked outside

  useEffect(() => {
    if (searchQuery.trim()) {
      const results = posts.filter((post: IPost) =>
        post.title.toLowerCase().includes(searchQuery.trim().toLowerCase())
      );
      setFilteredPosts(results);
      setIsDropdownVisible(true);
    } else {
      setFilteredPosts([]);
      setIsDropdownVisible(false);
    }
  }, [searchQuery, posts]);

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleResultClick = (id: string) => {
    if (onResultClick) {
      onResultClick(id); // Trigger optional callback on result click
    }
    setIsDropdownVisible(false); // Close the dropdown after clicking a result
    setSearchQuery(""); // Clear the search input after selection
  };

  return (
    <div className={clsx("relative", classNames.container)}>
      {/* Search input */}
      <Input
        placeholder={placeholder}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        startContent={<IoSearch />}
        classNames={{
          base: clsx("max-w-full sm:max-w-[12rem] h-10 hidden sm:flex", classNames.input),
          mainWrapper: "h-full",
          input: "text-small",
          inputWrapper: "h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20",
        }}
      />

      {/* Dropdown for search results */}
      {isDropdownVisible && filteredPosts.length > 0 && (
        <div
          ref={dropdownRef}
          className={clsx(
            "absolute z-10 w-full sm:w-[30rem] mt-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg max-h-60 overflow-y-auto overflow-x-hidden",
            classNames.dropdown
          )}
        >
          {filteredPosts.map((post) => (
            <Link
              key={post.id}
              to={`/post/${post.id}`}
              className={clsx("block w-full text-left p-3 hover:bg-gray-100 dark:hover:bg-gray-700", classNames.resultItem)}
              onClick={() => handleResultClick(post.id)}
            >
              <h4 className="text-sm font-semibold text-gray-800 dark:text-gray-200">{post.title}</h4>
              <p className="text-xs text-gray-600 dark:text-gray-400">{post.body.substring(0, 100)}...</p>
            </Link>
          ))}
        </div>
      )}

      {/* No results found */}
      {isDropdownVisible && filteredPosts.length === 0 && (
        <div
          ref={dropdownRef}
          className={clsx(
            "absolute z-10 w-full sm:w-[30rem] mt-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg p-3 text-gray-500 dark:text-gray-400",
            classNames.noResults
          )}
        >
          No results found for "{searchQuery}"
        </div>
      )}
    </div>
  );
};

export default SearchInput;
