import { Outlet, ScrollRestoration } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import CookieConsent from "./components/CookieConsent";


import usePageView from "./hooks/usePageView";



export default function Layout() {
	usePageView()
	return (
		<div className="flex min-h-screen flex-col">
			<header className="fixed left-0 top-0 z-50 w-full">
				<NavBar />
			</header>
			<main className="flex-grow pt-16">
				<div className="container mx-auto px-8 sm:px-16 md:px-40">
					<Outlet />
				</div>
			</main>
			<div className="bottom-0 left-0 right-0 w-full">
				<Footer />
			</div>

			<ScrollRestoration />

			<CookieConsent />

		</div>
	);
}
