// MarkdownEditor.tsx
import { useState, useEffect } from "react";
import MDEditor, {ICommand} from "@uiw/react-markdown-editor";
import useTheme from "../hooks/useTheme";
import { BsArrowsExpand } from "react-icons/bs";
import { CgArrowsMergeAltH } from "react-icons/cg";


interface IMarkdownEditorProps {
	markdown: string;
	setMarkdown: (value: string) => void;
}


const Centered: ICommand = {
	name: "Centered",
	keyCommand: "Centered",
	icon: <CgArrowsMergeAltH />,
	execute: ({ state, view }) => {
	  if (!state || !view) return;
  
	  const { from, to } = view.state.selection.main; // Get the selected range
	  const selectedText = view.state.doc.sliceString(from, to); // Extract the selected text
  
	  // Wrap all selected lines with a centering div
	  const centeredText = `<div style="text-align:center;">\n${selectedText}\n</div>`;
  
	  view.dispatch({
		changes: {
		  from,
		  to,
		  insert: centeredText,
		},
		selection: { anchor: from, head: from + centeredText.length }, // Update selection
	  });
	},
  };
  
  

const BreakLine: ICommand = {
	name: "BreakLine",
	keyCommand: "BreakLine",
	icon: (
		<BsArrowsExpand />

	  
	),
	execute: ({ state, view }) => {
	  if (!state || !view) return;
	  const lineInfo = view.state.doc.lineAt(view.state.selection.main.from);
	  let mark = "<br/>";
	  const matchMark = lineInfo.text.match(/^#+/);
	  if (matchMark && matchMark[0]) {
		const txt = matchMark[0];
		if (txt.length < 6) {
		  mark = txt + "#";
		}
	  }
	  if (mark.length > 6) {
		mark = "#";
	  }
	  const title = lineInfo.text.replace(/^#+/, "");
	  view.dispatch({
		changes: {
		  from: lineInfo.from,
		  to: lineInfo.to,
		  insert: `${mark}${title}`
		},
		// selection: EditorSelection.range(lineInfo.from + mark.length, lineInfo.to),
		selection: { anchor: lineInfo.from + mark.length }
	  });
	}
  };

const MarkdownEditor = ({ markdown, setMarkdown }: IMarkdownEditorProps) => {
	const { isDarkTheme, theme } = useTheme();

	useEffect(() => {
		console.log("Theme change in MarkdownEditor:", isDarkTheme);
		console.log("Theme change in MarkdownEditor:", theme);
	}, [isDarkTheme, theme]);

	return (
		<div className={`flex flex-col md:flex-row`}>
			<div
				id="markdown-editor-parent"
				className="flex-1 overflow-hidden p-4"
				data-color-mode={isDarkTheme ? "dark" : "light"} //TODO: Dark mode not working without full page refresh // Maybe I should use ThemeContext and in there useTheme hook
			>
				<MDEditor
					value={markdown}
					enableScroll={true}
					height="80vh"
					onChange={setMarkdown}
					className={`${isDarkTheme ? "dark" : ""}`}
					toolbars={["undo", "redo", "bold", "italic", "strike", "quote", "ulist", "link", "underline", "header", Centered, BreakLine]}
				/>
			</div>
		</div>
	);
};

export default MarkdownEditor;
