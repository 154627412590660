import React from "react";
import BlogPostCard from "./BlogPostCard";
import { usePosts } from "../context/PostsContext"; 

const RecentThreePostList = () => {
	const { posts, loading, error } = usePosts(); // Get posts, loading, and error states from PostsContext

	if (loading) return <div>Loading...</div>;
	if (error) return <div>{error}</div>;

	// Get the first three posts from the posts array
	const recentThreePosts = posts.slice(0, 3);

	return (
		<div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
			{recentThreePosts.map((post: any) => (
				<BlogPostCard key={post.id} post={post} />
			))}
		</div>
	);
};

export default RecentThreePostList;
