import useTheme from "../hooks/useTheme";

const ThemeToggle = () => {
	const { theme, toggleTheme } = useTheme();

	return (
		<button
			onClick={toggleTheme}
			className="theme-toggle-button rounded-full bg-gray-200 p-2 text-gray-700 dark:bg-gray-700 dark:text-gray-200"
		>
			<span className="hidden sm:inline">
				{theme === "light"
					? "🌞 Light"
					: theme === "dark"
						? "🌜 Dark"
						: "💻 System"}
			</span>
			<span className="inline sm:hidden">
				{theme === "light" ? "🌞" : theme === "dark" ? "🌜" : "📱"}
			</span>
		</button>
	);
};

export default ThemeToggle;
