import ShareButton from "./ShareButton";
import { IoIosClose } from "react-icons/io";
import { FaEnvelope, FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { MdMessage } from "react-icons/md";

const ShareModal = ({ isOpen, onClose, title, url }) => {
  const shareTitle = encodeURIComponent(title);
  const shareSummary = encodeURIComponent("Check out this blog post!");

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${shareSummary}\n${shareTitle} - ${url}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${shareTitle}&url=${url}`;
  const emailShareUrl = `mailto:?subject=${shareTitle}&body=${shareSummary} - ${url}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const imessageShareUrl = `sms:?body=${shareTitle} - ${url}`;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg transition-all duration-300">
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100 text-center">
          Share this post
        </h2>
        <div className="flex flex-col space-y-3">
          <a
            href={imessageShareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-blue-700 hover:text-blue-500 transition duration-200"
          >
            <MdMessage className="mr-2" /> Share via Text Message
          </a>
          <a
            href={whatsappShareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-green-600 hover:text-green-500 transition duration-200"
          >
            <FaWhatsapp className="mr-2" /> Share on WhatsApp
          </a>
          <a
            href={emailShareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-red-700 hover:text-red-500 transition duration-200"
          >
            <FaEnvelope className="mr-2" /> Share via Email
          </a>
          <a
            href={twitterShareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-blue-500 hover:text-blue-400 transition duration-200"
          >
            <FaTwitter className="mr-2" /> Share on X
          </a>
          <a
            href={facebookShareUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-blue-600 hover:text-blue-500 transition duration-200"
          >
            <FaFacebook className="mr-2" /> Share on Facebook
          </a>
          
        </div>
        <div className="flex justify-center items-center space-x-4 pt-5">
  <ShareButton title={shareTitle} text={shareTitle} url={url} />
  <button
    onClick={onClose}
    className="flex items-center text-red-500 hover:text-red-400 transition duration-200"
  >
    <IoIosClose size={28} className="mr-2" /> Close
  </button>
</div>
        
      </div>
    </div>
  );
};

export default ShareModal;
