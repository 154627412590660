import React from "react";
import { Link } from "react-router-dom";
import { IoPerson } from "react-icons/io5";
import { BsCalendar2DateFill } from "react-icons/bs";

interface BlogPostItemProps {
	post: {
		id: string;
		authorName: string;
		title: string;
		excerpt: string;
		mainImage: string;
		authorsId: string[];
		tags: string[];
		date: string;
	};
}

const BlogPostItem: React.FC<BlogPostItemProps> = ({ post }) => {
	return (
		<div className="mb-8 flex h-56 flex-col overflow-hidden rounded-lg bg-white shadow-md dark:bg-gray-800 dark:text-white sm:h-48 sm:flex-row">
			<div className="relative h-1/3 w-full sm:h-full sm:w-2/5">
				<img
					src={post.mainImage}
					alt={post.title}
					className="h-14 w-full sm:w-full object-cover sm:h-full"
				/>
			</div>
			<div className="flex w-full flex-col justify-between p-6 sm:w-3/5">
				<div className="overflow-hidden">
					<Link
						to={`/post/${post.id}`}
						className="text-2xl font-bold text-blue-600 hover:underline dark:text-blue-400"
					>
						{post.title}
					</Link>
					<p className="mt-2 text-gray-600 dark:text-gray-300 sm:text-sm line-clamp-3">
						{post.excerpt}
					</p>
				</div>
				<div className="bottom-0 mt-2 text-sm">
					<div className="mt-2 flex sm:flex-row text-gray-500 dark:text-gray-400 flex-col">
						<span className="mr-2 flex items-center">
							<BsCalendar2DateFill size={18} className="mr-1" />
							{new Date(post.date).toLocaleDateString()}
						</span>
						<span className="flex items-center">
							<IoPerson size={18} className="ml-2 mr-1" />
							{post.authorName}
						</span>
					</div>
					<div className="mt-4 flex flex-nowrap space-x-2 overflow-x-auto scrollbar-hide">
						{post.tags.map((tag) => (
							<span
								key={tag}
								className="mb-2 flex-shrink-0 rounded-full bg-blue-500 px-2 py-1 text-xs font-medium text-white dark:bg-blue-400"
							>
								{tag}
							</span>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogPostItem;
